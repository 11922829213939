document.addEventListener('DOMContentLoaded', () => {
    const carouselContainer = document.querySelector('.carousel-container');
    const prevArrow = document.querySelector('.prev-arrow');
    const nextArrow = document.querySelector('.next-arrow');
    const dots = document.querySelectorAll('.dot');
    const postCardWidth = carouselContainer.querySelector('.post-card').offsetWidth;

    if (carouselContainer) {
    
      const activeDotIndex = 0; 
      dots[activeDotIndex].classList.add('active-dot');

      prevArrow.addEventListener('click', () => {
          carouselContainer.scrollLeft -= postCardWidth;
      });

      nextArrow.addEventListener('click', () => {
          carouselContainer.scrollLeft += postCardWidth;

      });

      carouselContainer.addEventListener('scroll', () => {
          const scrollPosition = carouselContainer.scrollLeft;
          const currentIndex = Math.round(scrollPosition / postCardWidth);
          updateActiveDot(currentIndex);
      });

      dots.forEach((dot, index) => {
          dot.addEventListener('click', () =>{
              const selectedPost = carouselContainer.querySelectorAll('.post-card')[index];
              const offsetLeft = selectedPost.offsetLeft;
              const containerWidth = carouselContainer.offsetWidth;
              const scrollPosition = offsetLeft - (containerWidth / 2) + (postCardWidth / 2);
              carouselContainer.scrollTo({
                  left: scrollPosition,
                  behavior: 'smooth'
              });
              updateActiveDot(index);
          });
      });

      let updateActiveDot = (index) => {
          dots.forEach((dot, dotIndex) => {
              if (dotIndex === index) {
                  dot.classList.add('active-dot');
              } else {
                  dot.classList.remove('active-dot');
              }
          });
      }

      let updateArrowColor = () => {
        const maxScrollLeft = carouselContainer.scrollWidth - carouselContainer.clientWidth;
        if (carouselContainer.scrollLeft >= maxScrollLeft) {
          nextArrow.querySelector('path').setAttribute('stroke', '#A1A1AA');
        } else {
          nextArrow.querySelector('path').setAttribute('stroke', '#E22944');
        }
        if (carouselContainer.scrollLeft === 0) {
          prevArrow.querySelector('path').setAttribute('stroke', '#A1A1AA');
        } else {
          prevArrow.querySelector('path').setAttribute('stroke', '#E22944');
        }
      };
        
      prevArrow.addEventListener('click', () => {
        carouselContainer.scrollLeft -= postCardWidth;
        updateArrowColor();
      });
      
      nextArrow.addEventListener('click', () => {
        carouselContainer.scrollLeft += postCardWidth;
        updateArrowColor();
      });

      // Initial active dot
      updateArrowColor();
    }
});